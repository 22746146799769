import React from "react";
import { Breakpoint } from "react-socks";
import DesktopNav from "./DesktopNav";
import MobileNav from "./MobileNav";
import { Routes } from "../../utils/routes";

const Nav = () => {
  if (window.location.pathname === Routes.JagaApp) {
    return null;
  }

  return (
    <>
      <Breakpoint small down>
        <MobileNav />
      </Breakpoint>
      <Breakpoint medium up>
        <DesktopNav />
      </Breakpoint>
    </>
  );
};

export default Nav;
