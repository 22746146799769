import React, { useEffect, useState } from "react";
import { NavLink, useNavigate } from "react-router-dom";
import { Breakpoint } from "react-socks";
import { useContainer } from "unstated-next";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faGlobeAsia, faSearch } from "@fortawesome/free-solid-svg-icons";

import Dropdown from "../Dropdown";
import { GlobalStateContainer } from "../../provider/GlobalStateContainer";
import UserAvatar from "../UserAvatar";
import SearchFilter from "../SearchFilter";
import i18n from '../../i18n/i18n';

import auth from "../../utils/auth";
import { AuthModalMode } from "../../utils/constants";
import { Routes } from "../../utils/routes";

import SearchIcon from "../../images/search-icon.png";
import Logo from "../../images/parkit-logo-horizontal.png";

const DesktopNav = () => {
  const navigate = useNavigate();
  const { searchedLocationCoordinates, authedUser, setAuthModalState } = useContainer(GlobalStateContainer);
  const [prevScrollpos, setPrevScrollpos] = useState(window.scrollY);
  const [visible, setVisible] = useState(true);
  const authenticated = Boolean(auth.getRefreshToken());

  useEffect(() => {
    const handleScroll = () => {
      const currentScrollPos = window.scrollY;
      setVisible(prevScrollpos > currentScrollPos);
      setPrevScrollpos(currentScrollPos);
    };

    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, [prevScrollpos]);

  const handleFindButton = () => {
    if (!searchedLocationCoordinates) {
      alert(`${i18n.t("findParking.chooseLocation")}`);
    } else {
      // Google Ads Tracking
      window.dataLayer = window.dataLayer || [];
      function gtag() { window.dataLayer.push(arguments); }
      gtag('js', new Date());

      gtag('config', 'AW-803504205');
      function gtag_report_conversion(url) {
        gtag('event', 'conversion', {
          'send_to': 'AW-803504205/LgYxCL6JrdcBEM2Akv8C',
          'event_callback': function () { }
        });
        return false;
      }
      gtag_report_conversion();
      navigate(Routes.FindParking, { replace: true });
    }
  };

  const setLanguage = (language) => {
    localStorage.setItem('lang', language);
    window.location.reload();
  }

  return (
    <nav className={`flex justify-between items-center mobile no-underline font-medium w-full px-3 my-auto bg-white text-[#cccccc] z-20 fixed ${visible ? "top-0" : "-top-[80px]"}`}>
      <ul className="h-[75px] cursor-pointer">
        <li className="flex h-full items-center">
          <button onClick={() => navigate(Routes.Home)}>
            <img className="h-[50px] mr-2" src={Logo} alt="logo" />
          </button>
        </li>
      </ul>
      <ul className="flex items-center gap-3 sm:gap-5 ml-auto pr-0">
        {window.location.pathname !== "/" && window.location.pathname !== "/find-parking/search" && (
          <Breakpoint xlarge up>
            <ul className="flex flex-row ml-auto">
              <SearchFilter
                placeholder={i18n.t("findParking.parkWhere")}
                height={48}
                hideIcon={true}
                className="w-[200px] flex items-center justify-center"
                inputClassName="w-full h-[48px] pl-[38px] text-[12px] text-left rounded-[18px] bg-no-repeat bg-left-[10px] bg-top-[8px] text-black"
                style={{
                  backgroundImage: `url(${SearchIcon})`,
                  backgroundRepeat: "no-repeat",
                  backgroundPosition: "left 10px top 8px",
                }}
                onPlaceSelected={handleFindButton}
              />
              <button type="button" onClick={handleFindButton} className="text-white bg-gradient-to-r from-[#e76c6d] to-[#b54243] border-none font-medium text-[14px] rounded-[24px] outline-none shadow m-[10px] p-[12px] text-center h-[48x] min-w-[48px]">
                <FontAwesomeIcon icon={faSearch} className="ml-0" />
              </button>
            </ul>
          </Breakpoint>
        )}
        <li className="hover:text-primary">
          <NavLink to={Routes.About}>
            {i18n.t("navigation.about")}
          </NavLink>
        </li>
        <li className="hover:text-primary">
          <NavLink to={Routes.Faq}>
            {i18n.t("navigation.faq")}
          </NavLink>
        </li>
        <li className="hover:text-primary">
          <NavLink to={Routes.Owners}>
            {i18n.t("navigation.owners")}
          </NavLink>
        </li>
        <li className="hover:text-primary">
          <NavLink to={Routes.Parkers}>
            {i18n.t("navigation.parkers")}
          </NavLink>
        </li>
        <Dropdown
          dropdownParent={(
            <NavLink to={Routes.BusinessSolutions}>
              {i18n.t("navigation.businessSolutions")}
            </NavLink>
          )}
        >
          <div className="absolute right-0">
            <div className="bg-white w-[200px] mt-[10px] text-[#DBDBDB] rounded-md border p-[10px] flex flex-col gap-2">
              <NavLink to={Routes.BulkListing} className="hover:text-primary">{i18n.t("navigation.bulkListing")}</NavLink>
              <hr />
              <NavLink to={Routes.BulkRenting} className="hover:text-primary">{i18n.t("navigation.bulkRenting")}</NavLink>
            </div>
          </div>
        </Dropdown>
        <li className="hover:text-primary">
          <NavLink to={Routes.AddListing}>
            {i18n.t("navigation.listAParking")}
          </NavLink>
        </li>
        <Dropdown
          dropdownParent={(
            <NavLink to="#" className="flex"><FontAwesomeIcon icon={faGlobeAsia} /></NavLink>
          )}
        >
          <div className="absolute right-0">
            <div className="bg-white w-[200px] mt-[10px] text-[#DBDBDB] rounded-md border p-[10px] flex flex-col gap-2">
              <button className="hover:text-primary text-left" onClick={() => setLanguage('en')}>Malaysia</button>
              <hr />
              <button className="hover:text-primary text-left" onClick={() => setLanguage('zh-HK')}>Hong Kong</button>
            </div>
          </div>
        </Dropdown>
        {authenticated ? (
          <UserAvatar imageUrl={authedUser?.profilePicture?.url} />
        ) : (
          <>
            <li className="hover:text-primary">
              <button onClick={() => setAuthModalState({ isModalOpen: true, mode: AuthModalMode.LOGIN })} type="button">{i18n.t("navigation.login")}</button>
            </li>
            <li className="hover:text-primary">
              <button onClick={() => setAuthModalState({ isModalOpen: true, mode: AuthModalMode.SIGNUP })} type="button">{i18n.t("navigation.register")}</button>
            </li>
          </>
        )}
      </ul>
    </nav>
  );
};

export default DesktopNav;
