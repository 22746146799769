import React from 'react';
import { Outlet, Navigate } from 'react-router-dom';
import { useContainer } from 'unstated-next';
import auth from '../../utils/auth';
import { Routes } from '../../utils/routes';
import { AuthModalMode } from '../../utils/constants';
import { GlobalStateContainer } from '../../provider/GlobalStateContainer';

const PrivateRoute = () => {
  const authenticated = Boolean(auth.getRefreshToken());
  const { setAuthModalState } = useContainer(GlobalStateContainer);

  return authenticated ? (
    <Outlet />
  ) : (
    <>
      {/* Redirect to the home route */}
      <Navigate to={Routes.Home} replace />

      {/* Open the authentication modal with the login mode */}
      {setAuthModalState({ isModalOpen: true, mode: AuthModalMode.LOGIN })}
    </>
  );
};

export default PrivateRoute;
